import React, { useState, useEffect, useContext } from 'react';
import { Play, Clock, Headphones, ChevronDown, ChevronUp } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Header from './Header'; // Import the Header component
import { AuthContext } from './AuthContext'; // Import AuthContext to manage user state
import { auth } from './firebase';
import { signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import supabase from './supabaseClient';

const PodcastLibrary = () => {
  const [podcasts, setPodcasts] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPodcasts = async () => {
      if (!user) {
        setLoading(false); // Set loading to false if the user is not logged in
        return; // If the user is not logged in, don't fetch podcasts
      }

      try {
        // Fetch the user's data from the 'users' table based on email/device token
        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('podcast_ids')
          .eq('id', user.email); // Or device token if anonymous

        if (userError) throw userError;

        if (userData && userData.length > 0) {
          let podcastIds = userData[0].podcast_ids;

          // Debugging output: Check the content of podcastIds

          // Ensure podcastIds is parsed from string to an array of UUIDs
          if (typeof podcastIds === 'string') {
            try {
              podcastIds = JSON.parse(podcastIds); // Parse the JSON string to an array
            } catch (parseError) {
              console.error('Error parsing podcastIds:', parseError);
              podcastIds = [];
            }
          }

          // Validate parsed podcastIds
          if (Array.isArray(podcastIds) && podcastIds.every(id => typeof id === 'string')) {
            // Fetch the podcasts from the 'podcasts' table based on the podcast_ids array
            const { data: podcastsData, error: podcastsError } = await supabase
              .from('podcasts')
              .select('*')
              .in('podcast_id', podcastIds); // Pass the podcastIds directly

            if (podcastsError) throw podcastsError;


            const podcastsList = podcastsData.map((podcast) => ({
              id: podcast.podcast_id,
              title: podcast.title,
              description: podcast.description || 'This is a sample description for this part.',
              image: podcast.podcast_image,
              url: podcast.url,
              type: podcast.type, // Include type in the podcast data
            }));

            setPodcasts(podcastsList);
          } else {
            console.error('podcastIds is not an array of valid UUIDs:', podcastIds);
            setPodcasts([]); // No podcasts for this user
          }
        }
      } catch (error) {
        console.error('Error fetching podcasts:', error);
      } finally {
        setLoading(false); // Ensure loading is set to false after fetching
      }
    };

    fetchPodcasts();
  }, [user]);

  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check if the user already exists
      const { data: existingUser, error: fetchError } = await supabase
        .from('users')
        .select('id')
        .eq('id', user.email)

      if (fetchError) {
        console.error('Error fetching user:', fetchError);
        return;
      } 

      if (Array.isArray(existingUser) && existingUser.length === 0) {
        // User doesn't exist, insert with default values
        const { error: insertError } = await supabase.from('users').insert([
          {
            id: user.email,
            generated_episodes: 0,
            generated_episodes_today: 0,
            created_at: new Date().toISOString(),
          }          
        ]);

        if (insertError) {
          console.error('Error inserting user:', insertError);
          return;
        }

      } else {
        console.log('User already exists, no need to insert.');
      }

      // Set user state and store in localStorage
      setUser(user);
      localStorage.setItem('authUser', JSON.stringify(user));
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  const handlePodcastClick = (podcast) => {
    if (podcast.type === 'playlist-container') {
      navigate(`/playlist?ID=${podcast.id}`);
    } else {
      navigate(`/podcast?ID=${podcast.id}`);
    }
  };

  const filteredPodcasts = podcasts.filter(
    (podcast) =>
      podcast.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      podcast.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="bg-[#121212] min-h-screen text-gray-100">
      {/* Use Header Component */}
      <Header searchTerm={searchTerm} onSearch={handleSearch} />

      {/* Main Content Section */}
      <main className="max-w-4xl mx-auto px-6 py-8">
        {loading ? (
          <p className="text-center text-white text-lg">Loading...</p>
        ) : !user || !user.email ? (
          <div className="text-center py-20">
            <h2 className="text-2xl font-bold text-white mb-4">No User Logged In</h2>
            <p className="text-gray-400 mb-6">
              Please log in to view your podcast library. If you're browsing anonymously, log in or sign up to create your own true crime podcasts for free and save them here.
            </p>
            <button
              onClick={handleGoogleSignIn}
              className="bg-[#3a86ff] text-white px-6 py-2 rounded-full font-semibold hover:bg-[#2a75f0] transition-colors duration-300"
            >
              Log In with Google
            </button>
          </div>
        ) : filteredPodcasts.length > 0 ? (
          <div className="space-y-6">
            {filteredPodcasts.map((podcast) => (
              <div
                key={podcast.id}
                className="bg-[#1f1f1f] rounded-lg overflow-hidden shadow-sm transition duration-300 hover:shadow-md p-4 flex items-start cursor-pointer" // Add cursor-pointer class
                onClick={() => handlePodcastClick(podcast)} // Add onClick handler
              >
                {/* Podcast Image */}
                <img
                  src={podcast.image}
                  alt={podcast.title}
                  className="w-24 h-24 object-cover rounded-lg mr-4"
                />
                {/* Podcast Details */}
                <div className="flex-grow">
                  <h3 className="text-xl font-semibold mb-2 text-white">{podcast.title}</h3>
                  <p className="text-sm text-gray-400 mb-2">
                    {expandedDescriptions[podcast.id]
                      ? podcast.description
                      : `${podcast.description.split('. ').slice(0, 1).join('. ')}.`}
                  </p>
                  <button
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent click event from propagating to parent
                      toggleDescription(podcast.id);
                    }}
                    className="text-[#3a86ff] text-sm font-medium flex items-center hover:text-[#2a75f0]"
                  >
                    {expandedDescriptions[podcast.id] ? (
                      <>
                        Show Less <ChevronUp className="w-4 h-4 ml-1" />
                      </>
                    ) : (
                      <>
                        Show More <ChevronDown className="w-4 h-4 ml-1" />
                      </>
                    )}
                  </button>
                </div>
                {/* Play Button */}
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent click event from propagating to parent
                    handlePodcastClick(podcast); // Trigger navigation when the play button is clicked
                  }}
                  className="bg-[#3a86ff] text-white rounded-full p-2 hover:bg-[#2a75f0] transition-colors duration-300 ml-4"
                >
                  <Play className="w-6 h-6" />
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-white text-lg">No podcasts found for your account.</p>
        )}
      </main>
    </div>
  );
};

export default PodcastLibrary;
