import React, { useEffect, useState, useContext } from 'react';
import { usePodcast } from './PodcastContext'; // Import Podcast context
import {
  Play,
  Pause,
  FastForward,
  Rewind,
  MoreHorizontal,
  Share2,
  CheckCircle,
  Loader,
  ChevronRight,
} from 'lucide-react';
import Header from './Header';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { auth } from './firebase'; // Import the Firebase auth object
import { signInAnonymously } from 'firebase/auth';
import supabase from './supabaseClient';

const PodcastPlayer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const partId = searchParams.get('ID');
  const { user, setUser } = useContext(AuthContext);

  // Use the global podcast context
  const {
    audioRef,
    audioSrc,
    setAudioSrc,
    isPlaying,
    playAudio,
    pauseAudio,
    progress,
    setAudioPosition,
    handleTimeUpdate,
    duration,
    playbackRate,
    changePlaybackSpeed,
    rewindAudio,
    fastForwardAudio,
    podcastTitle,
    setPodcastTitle,
    podcastImage,
    setPodcastImage,
  } = usePodcast();

  const [podcastDescription, setPodcastDescription] = useState('');
  const [loading, setLoading] = useState(true);
  const [isLongForm, setIsLongForm] = useState(false);
  const [otherParts, setOtherParts] = useState([]);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    const anonymousLogin = async () => {
      const storedAuthUser = localStorage.getItem('authUser');
      if (storedAuthUser) {
        const authUser = JSON.parse(storedAuthUser);
        setUser(authUser);
      } else {
        try {
          const userCredential = await signInAnonymously(auth);
          const authUser = userCredential.user;
          localStorage.setItem('authUser', JSON.stringify(authUser));
          setUser(authUser);
        } catch (error) {
          console.error('Anonymous login failed:', error);
        }
      }
    };

    anonymousLogin();
  }, [setUser]);

  useEffect(() => {
    const fetchPodcastById = async () => {
      if (!user) return;

      try {
        setLoading(true);

        // Check the cache for the podcast by ID
        const cachedPodcasts = JSON.parse(localStorage.getItem('all_podcasts_cached_newest10')) || [];
        let selectedPodcast = cachedPodcasts.find((podcast) => podcast.podcast_id === partId);

        if (!selectedPodcast) {
          // Fetch the podcast from Supabase if not found in cache
          const { data, error } = await supabase
            .from('podcasts')
            .select('*')
            .eq('podcast_id', partId)
            .single();

          if (error) {
            console.error('Error fetching podcast from Supabase:', error);
            setLoading(false);
            return;
          }

          selectedPodcast = data;

          // Cache the newly fetched podcast
          const updatedCachedPodcasts = [...cachedPodcasts, selectedPodcast];
          localStorage.setItem('all_podcasts_cached_newest10', JSON.stringify(updatedCachedPodcasts));
        }

        // Process the selected podcast
        if (selectedPodcast && typeof selectedPodcast === 'object') {
          incrementPodcastViews(selectedPodcast.podcast_id); // Increment views by 1

          if (selectedPodcast.type === 'single') {
            // Handle 'single' type podcast
            setAudioSrc(selectedPodcast.url); // Set audio source globally
            setPodcastTitle(selectedPodcast.title); // Set podcast title globally
            setPodcastDescription(selectedPodcast.description);
            setPodcastImage(selectedPodcast.podcast_image || 'https://via.placeholder.com/150'); // Set podcast image globally
            setIsLongForm(false);
          } else if (selectedPodcast.type === 'long-form') {
            // Handle 'long-form' type podcast (part of a playlist)
            setAudioSrc(selectedPodcast.url); // Set audio source globally
            setPodcastTitle(selectedPodcast.title); // Set podcast title globally
            setPodcastDescription(selectedPodcast.description || 'No description available.');
            setPodcastImage(selectedPodcast.podcast_image || 'https://via.placeholder.com/150');
            setIsLongForm(true);

            // Fetch other parts in the playlist
            const podcastsInPlaylist = JSON.parse(selectedPodcast.podcasts_in_playlist || '[]'); // Parse the playlist
            const { data: playlistParts, error: playlistError } = await supabase
              .from('podcasts')
              .select()
              .in('podcast_id', podcastsInPlaylist);

            if (playlistError) {
              console.error('Error fetching playlist parts from Supabase:', playlistError);
            } else {
              const orderedParts = podcastsInPlaylist.map((podcastId) =>
                playlistParts.find((part) => part.podcast_id === podcastId)
              );

              // Set the other parts of the playlist in the sidebar, keeping them in order
              setOtherParts(orderedParts);
            }
          }
        } else {
          console.log('Podcast type is playlist-container or not recognized, ignoring.');
        }

        resetPlayerState(); // Reset player state
      } catch (error) {
        console.error('Error fetching podcast by ID:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPodcastById();
  }, [user, partId, setAudioSrc, setPodcastTitle, setPodcastImage]);

  useEffect(() => {
    if (audioRef.current && isLongForm) {
      const handleAudioEnd = () => {
        const currentIndex = otherParts.findIndex((part) => part.podcast_id === partId);
        if (currentIndex >= 0 && currentIndex < otherParts.length - 1) {
          const nextPart = otherParts[currentIndex + 1];
          navigate(`?ID=${nextPart.podcast_id}`);
        }
      };

      audioRef.current.addEventListener('ended', handleAudioEnd);

      // Cleanup the event listener when component unmounts or dependencies change
      return () => {
        if (audioRef.current) {
          audioRef.current.removeEventListener('ended', handleAudioEnd);
        }
      };
    }
  }, [audioRef, isLongForm, otherParts, partId, navigate]);

  // Function to increment podcast views
  const incrementPodcastViews = async (podcastId) => {
    try {
      // Call the RPC function to increment views
      const { data, error } = await supabase.rpc('increment_views', {
        cur_podcast_id: podcastId, // Pass the podcast_id as the parameter
      });

      if (error) {
        console.error('Error incrementing podcast views via RPC:', error);
      } else {
        console.log('');
      }
    } catch (error) {
      console.error('Error in incrementPodcastViews function:', error);
    }
  };

  // Function to reset player state when a new podcast is loaded
  const resetPlayerState = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      changePlaybackSpeed(1); // Ensure playback rate is 1x
    }
  };

  // Function to handle search and redirect to home page
  const handleSearch = (e) => {
    const searchTerm = e.target.value; // Extract search term from event
    navigate(`/`, { state: { searchTerm } }); // Navigate to home page with search term
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      pauseAudio();
    } else {
      playAudio();
    }
  };

  const handleSeek = (event) => {
    const seekTime = (event.target.value / 100) * duration;
    setAudioPosition(seekTime); // Set position globally
  };

  const sharePodcast = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
      })
      .catch((error) => console.error('Could not copy text: ', error));
  };

  const handlePodcastClick = (podcastId) => {
    // Navigate to the new podcast without losing the left panel
    navigate(`?ID=${podcastId}`);
  };

  return (
    <div className="bg-gradient-to-b from-gray-900 to-black min-h-screen text-gray-100 relative font-sans">
      <Header searchTerm="" onSearch={handleSearch} />

      <main className="flex flex-col md:flex-row max-w-7xl mx-auto px-4 py-8">
        {isLongForm && (
          <aside className="w-full md:w-1/4 md:pr-8 mb-8 md:mb-0">
            <h3 className="text-xl font-bold mb-4">Episodes</h3>
            <ul className="space-y-4">
              {otherParts.map((part) => (
                <li
                  key={part.podcast_id}
                  className={`p-4 rounded-lg transition-colors ${
                    part.podcast_id === partId
                      ? 'bg-blue-600'
                      : 'bg-gray-800 hover:bg-gray-700'
                  }`}
                >
                  <button
                    onClick={() => handlePodcastClick(part.podcast_id)}
                    className="flex items-center w-full text-left"
                  >
                    <span className="flex-1 truncate">{part.title}</span>
                    <ChevronRight className="w-4 h-4 ml-2" />
                  </button>
                </li>
              ))}
            </ul>
          </aside>
        )}

        <div className={`${isLongForm ? 'w-full md:w-3/4' : 'w-full'}`}>
          {loading ? (
            <div className="flex justify-center items-center h-96">
              <Loader className="animate-spin text-white w-16 h-16" />
              <p className="text-white text-xl ml-4">Loading Podcast...</p>
            </div>
          ) : (
            <div className="bg-gray-800 rounded-lg p-8 shadow-lg">
              <div className="flex flex-col md:flex-row items-start mb-6">
                <img
                  src={podcastImage}
                  alt={podcastTitle}
                  className="w-full md:w-32 h-auto md:h-32 object-cover rounded-lg mr-0 md:mr-6 shadow-md mb-4 md:mb-0"
                />
                <div className="flex-1">
                  <h2 className="text-2xl md:text-3xl font-bold text-white mb-2">
                    {podcastTitle}
                  </h2>
                  <p className="text-sm md:text-md text-gray-400 mb-4">Golpo</p>
                  <div className="flex items-center mt-4">
                    <span className="text-sm text-gray-400 mr-4">
                      {formatTime((progress / 100) * duration)}
                    </span>
                    <input
                      type="range"
                      value={progress}
                      onChange={handleSeek}
                      className="flex-1 h-2 bg-gray-700 rounded-full appearance-none cursor-pointer"
                      style={{
                        background: `linear-gradient(to right, #3b82f6 ${progress}%, #374151 ${progress}%)`,
                      }}
                    />
                    <span className="text-sm text-gray-400 ml-4">
                      {formatTime(duration)}
                    </span>
                  </div>
                </div>
                <button className="text-gray-400 hover:text-white transition-colors mt-4 md:mt-0">
                  <MoreHorizontal className="w-6 h-6" />
                </button>
              </div>

              <div className="flex items-center justify-center space-x-6 mb-6">
                <button
                  onClick={() => rewindAudio()}
                  className="bg-blue-600 text-white rounded-full p-3 hover:bg-blue-700 transition-colors duration-300"
                >
                  <Rewind className="w-6 h-6" />
                </button>
                <button
                  onClick={togglePlayPause}
                  className="bg-blue-600 text-white rounded-full p-4 hover:bg-blue-700 transition-colors duration-300"
                >
                  {isPlaying ? (
                    <Pause className="w-8 h-8" />
                  ) : (
                    <Play className="w-8 h-8" />
                  )}
                </button>
                <button
                  onClick={() => fastForwardAudio()}
                  className="bg-blue-600 text-white rounded-full p-3 hover:bg-blue-700 transition-colors duration-300"
                >
                  <FastForward className="w-6 h-6" />
                </button>
                <select
                  value={playbackRate}
                  onChange={(e) => changePlaybackSpeed(parseFloat(e.target.value))}
                  className="bg-gray-700 text-white rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-300"
                >
                  <option value="1">1x</option>
                  <option value="1.25">1.25x</option>
                  <option value="1.5">1.5x</option>
                  <option value="2">2x</option>
                </select>
              </div>

              <div className="bg-gray-900 rounded-lg p-6">
                <h3 className="text-lg font-semibold text-white mb-2">
                  Episode Description
                </h3>
                <p className="text-sm text-gray-400 mb-4">
                  {showFullDescription
                    ? podcastDescription
                    : `${podcastDescription.substring(0, 175)}.`}
                  <button
                    onClick={() => setShowFullDescription(!showFullDescription)}
                    className="text-blue-400 hover:text-blue-300 ml-2 text-sm"
                  >
                    {showFullDescription ? 'Show Less' : 'Show More'}
                  </button>
                </p>
                <button
                  onClick={sharePodcast}
                  className="flex items-center text-white bg-gray-700 hover:bg-gray-600 px-4 py-2 rounded-full transition-colors"
                >
                  <Share2 className="w-5 h-5 mr-2" />
                  Share
                </button>
              </div>
            </div>
          )}
        </div>
      </main>

      {showToast && (
        <div className="fixed bottom-16 left-1/2 transform -translate-x-1/2 bg-blue-600 text-white px-4 py-2 rounded-full shadow-lg flex items-center">
          <CheckCircle className="w-5 h-5 mr-2" />
          Podcast link copied to clipboard!
        </div>
      )}
    </div>
  );
};

const formatTime = (seconds) => {
  if (isNaN(seconds)) return '00:00';
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes < 10 ? '0' : ''}${minutes}:${secs < 10 ? '0' : ''}${secs}`;
};

export default PodcastPlayer;
