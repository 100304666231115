import React, { useEffect, useState } from 'react';
import { isIOS } from './utls/deviceDetect';

const GetMobileApp = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isIOS()) {
      const hasSeenPopup = localStorage.getItem('hasSeenAppPopup');
      if (!hasSeenPopup) {
        setIsVisible(true);
      }
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem('hasSeenAppPopup', 'true');
  };

  const handleEsc = (e) => {
    if (e.key === 'Escape') {
      handleClose();
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('keydown', handleEsc);
    } else {
      document.removeEventListener('keydown', handleEsc);
    }
    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70 backdrop-blur-sm transition-opacity duration-300"
      role="dialog"
      aria-modal="true"
      aria-labelledby="popup-title"
      onClick={handleClose}
    >
      <div
        className="bg-white rounded-xl shadow-2xl max-w-sm w-full p-8 transform transition-all duration-300 scale-95 hover:scale-100"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 id="popup-title" className="text-2xl font-bold text-gray-900">
            Use Our Mobile App
          </h2>
          <button 
            onClick={handleClose} 
            className="text-gray-400 hover:text-gray-600 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <p className="text-gray-600 mb-8 leading-relaxed">
          Using the app will allow you many more features than the mobile site, such as creating your own true crime podcast!
        </p>
        <a
          href="https://apps.apple.com/app/golpo-true-crime-podcast/id6587557258"
          className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200 shadow-md"
        >
          Download the App
        </a>
      </div>
    </div>
  );
};

export default GetMobileApp;