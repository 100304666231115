import React, { useState, useEffect, useContext } from 'react';
import { Play, Clock, Headphones, ChevronDown, ChevronUp, PlusCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import createButton from './image.png'; // Adjust the path according to your file structure
import Header from './Header'; // Import the Header component
import Footer from './Footer'; // Import the Footer component
import { usePodcast } from './PodcastContext'; // Import the Podcast context
import { db, auth } from './firebase'; // Import Firebase Firestore and Auth instance
import { collection, getDocs, query, doc, updateDoc } from 'firebase/firestore';
import supabase from './supabaseClient';
import { AuthContext } from './AuthContext'; // Import AuthContext to manage user state
import { signInAnonymously } from 'firebase/auth';

const categories = [
  'Trending',
  'Recent',
  'Murder',
  'Historical', 
  'Legal',
  'Modern',
  'Infamous', 
  'Unsolved',
  'Fraud', 
  'Unique', 
  'Serial Killers'
];

const Discover = () => {
  const [allPodcasts, setAllPodcasts] = useState([]); // Original set of all podcasts
  const [filteredPodcasts, setFilteredPodcasts] = useState([]); // Filtered list of podcasts
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('Trending'); // New state for selected category
  const [loading, setLoading] = useState(true); // Loading state
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const anonymousLogin = async () => {
      const storedAuthUser = localStorage.getItem('authUser');
      if (storedAuthUser) {
        const authUser = JSON.parse(storedAuthUser);
        setUser(authUser);
      } else {
        try {
          const userCredential = await signInAnonymously(auth);
          const authUser = userCredential.user;
          localStorage.setItem('authUser', JSON.stringify(authUser));
          setUser(authUser);
        } catch (error) {
          console.error('Anonymous login failed:', error);
        }
      }
    };

    anonymousLogin();
  }, []);

  useEffect(() => {
    const fetchPodcasts = async () => {
      if (!user) return; // If the user is not logged in, don't fetch podcasts
      setLoading(true); // Start loading

      // Retrieve the last fetch timestamp from localStorage
      const lastFetchTimestamp = localStorage.getItem('last_fetch_timestamp10');
      const cachedPodcasts = JSON.parse(localStorage.getItem('all_podcasts_cached_newest10')) || [];
      
      // Filter cached podcasts immediately and sort by views (Trending)
      const initialFilteredPodcasts = cachedPodcasts
        .filter((podcast) => podcast.type === 'playlist-container' || podcast.type === 'single')
        .sort((a, b) => b.views - a.views); // Sort by views for Trending
      
      setAllPodcasts(initialFilteredPodcasts); // Set all podcasts for future filtering
      setFilteredPodcasts(initialFilteredPodcasts);
      setLoading(false); // Stop loading if there are cached podcasts
      
      let allFetchedPodcasts = [...initialFilteredPodcasts]; // Start with filtered cached podcasts
      let offset = 0; // To keep track of pagination
      const limit = 100; // Fetch 100 podcasts at a time
      let hasMore = true; // To control the loop
    
      // Fetch the latest views for cached podcasts in batches
      for (let i = 0; i < cachedPodcasts.length; i += limit) {
        const batchPodcastIds = cachedPodcasts.slice(i, i + limit).map((podcast) => podcast.podcast_id);
        
        const { data: updatedViews, error: cachedError } = await supabase
          .from('podcasts')
          .select('podcast_id, views') // Fetch only the podcast_id and views columns
          .in('podcast_id', batchPodcastIds); // Fetch podcasts by their IDs
    
        if (cachedError) {
          console.error('Error fetching latest views for cached podcasts:', cachedError);
        } else {
          // Update the allFetchedPodcasts array with the latest views data
          allFetchedPodcasts = allFetchedPodcasts.map((p) => {
            const updated = updatedViews.find((updated) => updated.podcast_id === p.podcast_id);
            return updated ? { ...p, views: updated.views } : p; // Update only the views field
          });
          setAllPodcasts(allFetchedPodcasts);
          setFilteredPodcasts([...allFetchedPodcasts].sort((a, b) => b.views - a.views)); // Sort by views
        }
      }
  
      localStorage.setItem('all_podcasts_cached_newest10', JSON.stringify(allFetchedPodcasts));
      // Fetch new podcasts in batches of 100
      while (hasMore) {
        let { data, error } = lastFetchTimestamp
          ? await supabase
              .from('podcasts')
              .select('*')
              .gt('timestamp', lastFetchTimestamp)
              .range(offset, offset + limit - 1) // Fetch in batches of 100
          : await supabase
              .from('podcasts')
              .select('*')
              .range(offset, offset + limit - 1); // Fetch in batches of 100 for the first time
    
        if (error) {
          console.error('Error fetching podcasts:', error);
          break;
        }
    
        if (data.length === 0) {
          hasMore = false; // No more podcasts to fetch
        } else {
          // Remove duplicates based on unique identifiers (e.g., 'podcast_id')
          const newPodcasts = data.filter(
            (newPodcast) =>
              !allFetchedPodcasts.some((existingPodcast) => existingPodcast.podcast_id === newPodcast.podcast_id)
          );

          // Filter new podcasts by 'single' and 'playlist-container' type
          const filteredNewPodcasts = newPodcasts.filter(
            (podcast) => podcast.type === 'playlist-container' || podcast.type === 'single'
          );

          // Append the new podcasts to the allFetchedPodcasts array
          allFetchedPodcasts = [...allFetchedPodcasts, ...filteredNewPodcasts];
    
          // Fetch the latest views for each newly fetched podcast in batches of `limit`
          for (let i = 0; i < filteredNewPodcasts.length; i += limit) {
            const newBatchPodcastIds = filteredNewPodcasts.slice(i, i + limit).map((podcast) => podcast.podcast_id);
    
            const { data: updatedNewViews, error: newPodcastsError } = await supabase
              .from('podcasts')
              .select('podcast_id, views') // Fetch only the podcast_id and views columns
              .in('podcast_id', newBatchPodcastIds); // Fetch all new podcasts by their IDs
    
            if (newPodcastsError) {
              console.error('Error fetching latest views for new podcasts:', newPodcastsError);
            } else {
              // Update the allFetchedPodcasts array with the latest views data
              allFetchedPodcasts = allFetchedPodcasts.map((p) => {
                const updated = updatedNewViews.find((updated) => updated.podcast_id === p.podcast_id);
                return updated ? { ...p, views: updated.views } : p; // Update only the views field
              });
            }
          }
    
          // Update the state with all fetched podcasts sorted by views (Trending)
          setAllPodcasts(allFetchedPodcasts);
          setFilteredPodcasts([...allFetchedPodcasts].sort((a, b) => b.views - a.views)); // Sort by views
    
          // Update cache after each batch
          localStorage.setItem('all_podcasts_cached_newest10', JSON.stringify(allFetchedPodcasts));
    
          offset += limit; // Increase offset for next batch
        }
      }
    
      // Update the last fetch timestamp
      localStorage.setItem('last_fetch_timestamp10', new Date().toISOString());
      setLoading(false); // End loading
    };
    fetchPodcasts();
  }, [user]); // Dependency array to re-run effect if user changes

  const handleCategoryClick = (category) => {
    setSelectedCategory(category); // Set the selected category

    const normalizedCategory = category.toLowerCase(); // Normalize selected category

    // Filter based on selected category using the original allPodcasts array
    if (normalizedCategory === 'trending') {
      setFilteredPodcasts([...allPodcasts].sort((a, b) => b.views - a.views));
    } else if (normalizedCategory === 'recent') {
      setFilteredPodcasts([...allPodcasts].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)));
    } else {
      const newFiltered = allPodcasts.filter((podcast) =>
        podcast.category
          .toLowerCase()
          .split(',')
          .map((c) => c.trim().replace(/["']/g, '')) // Remove quotes and trim
          .includes(normalizedCategory)
      );
      setFilteredPodcasts(newFiltered); // Update filtered podcasts
    }
  };

  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePodcastClick = (podcast) => {
    if (podcast.type === 'playlist-container') {
      navigate(`/playlist?ID=${podcast.podcast_id}`);
    } else {
      navigate(`/podcast?ID=${podcast.podcast_id}`);
    }
  };

  const filteredPodcastsToDisplay = filteredPodcasts.filter(
    (podcast) =>
      podcast.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      podcast.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="bg-[#121212] min-h-screen flex flex-col">
      {/* Use Header Component */}
      <Header searchTerm={searchTerm} onSearch={handleSearch} />

      {/* Main Content Section */}
      <main className="flex-1 px-6 py-8 overflow-y-auto w-full sm:max-w-7xl sm:mx-auto">
        <div className="flex gap-4 overflow-x-auto mb-10 py-3 scrollbar-hide">
          {categories.map((category, index) => (
            <button
              key={index}
              className={`px-5 py-2 rounded-full text-sm font-medium transition-colors duration-200 whitespace-nowrap ${
                selectedCategory === category ? 'bg-[#3a86ff] text-white' : 'bg-[#1f1f1f] text-gray-300 hover:bg-[#2a75f0] hover:text-white'
              }`}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </button>
          ))}
        </div>

        {/* Banner for creating new podcast */}
        {searchTerm !== '' && (
          <div className="bg-gradient-to-r from-[#3a86ff] to-[#2a75f0] rounded-lg p-6 mb-8 shadow-lg">
            <div className="flex items-center justify-between">
              <div className="flex-1">
                <h2 className="text-white text-2xl font-bold mb-2">Can't find what you're looking for?</h2>
                <p className="text-white mb-4">
                  Create your own, professional quality, true crime podcast on exactly what you're looking for (fully free). 
                </p>
                <button 
                  onClick={() => navigate('/create', { state: { searchTerm } })}
                  className="bg-white text-[#3a86ff] px-6 py-2 rounded-full font-semibold hover:bg-[#f0f0f0] transition-colors duration-300 flex items-center"
                >
                  Create Your Podcast
                  <PlusCircle className="ml-2 w-5 h-5" />
                </button>
              </div>
              <div className="hidden md:block">
                <img 
                  src={createButton} 
                  alt="Create Podcast" 
                  className="w-32 h-32 object-cover rounded-full border-4 border-white shadow-md"
                />
              </div>
            </div>
          </div>
        )}

        {/* Loading rectangles while fetching */}
        {loading ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {[...Array(8)].map((_, index) => (
              <div key={index} className="bg-[#2c2c2c] rounded-lg p-4 animate-pulse">
                <div className="w-full h-48 bg-[#3a3a3a] rounded-md mb-4"></div>
                <div className="w-3/4 h-4 bg-[#3a3a3a] rounded mb-2"></div>
                <div className="w-1/2 h-4 bg-[#3a3a3a] rounded"></div>
              </div>
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {filteredPodcastsToDisplay.map((podcast) => (
              <div key={podcast.podcast_id} className="bg-[#1f1f1f] rounded-lg overflow-hidden shadow-sm transition duration-300 hover:shadow-md">
                <div 
                  className="relative group cursor-pointer"
                  onClick={() => handlePodcastClick(podcast)}
                >
                  {/* Podcast Image */}
                  <img
                    src={podcast.podcast_image}
                    alt={podcast.title}
                    className="w-full h-48 object-cover"
                  />
                  {/* Play Button Overlay */}
                  <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <Play className="w-12 h-12 text-white" />
                  </div>
                  {/* Views Count */}
                </div>
                {/* Podcast Details */}
                <div className="p-4">
                  <h3 className="text-lg font-semibold mb-2 line-clamp-2 text-white">{podcast.title}</h3>
                  <div className={`text-sm text-gray-400 mb-2 ${expandedDescriptions[podcast.podcast_id] ? 'max-h-40 overflow-y-auto' : 'line-clamp-2'}`}>
                    {podcast.description}
                  </div>
                  <button
                    onClick={() => toggleDescription(podcast.podcast_id)}
                    className="text-[#3a86ff] text-sm font-medium flex items-center hover:text-[#2a75f0]"
                  >
                    {expandedDescriptions[podcast.podcast_id] ? (
                      <>
                        Show Less <ChevronUp className="w-4 h-4 ml-1" />
                      </>
                    ) : (
                      <>
                        Show More <ChevronDown className="w-4 h-4 ml-1" />
                      </>
                    )}
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </main>

      {/* Footer Section */}
      <Footer />
      <div className="bg-[#1f1f1f] py-4 text-center text-gray-400 text-sm mt-4">
        <p className="font-semibold text-white">
          A Red Tree Initiative 
        </p>
      </div>
    </div>
  );
};

export default Discover;
