import React, { useContext, useEffect, useState, useRef } from 'react';
import { Menu, Search, Library, User, LogOut, X } from 'lucide-react'; // Added X icon for closing sidebar
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { auth } from './firebase';
import { signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import supabase from './supabaseClient';

const Header = ({ searchTerm, onSearch }) => {
  const location = useLocation();
  const { user, setUser } = useContext(AuthContext);
  const [profilePicture, setProfilePicture] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for sidebar
  const dropdownRef = useRef(null);
  const sidebarRef = useRef(null); // Ref for sidebar

  useEffect(() => {
    if (user && user.photoURL) {
      setProfilePicture(user.photoURL);
    }
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close dropdown if clicked outside
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
      // Close sidebar if clicked outside
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check if the user already exists
      const { data: existingUser, error: fetchError } = await supabase
        .from('users')
        .select('id')
        .eq('id', user.email);
      
      if (fetchError) {
        console.error('Error fetching user:', fetchError);
        return;
      } 

      if (Array.isArray(existingUser) && existingUser.length === 0) {
        // User doesn't exist, insert with default values
        const { error: insertError } = await supabase.from('users').insert([
          {
            id: user.email,
            generated_episodes: 0,
            generated_episodes_today: 0,
            created_at: new Date().toISOString(),
          }          
        ]);

        if (insertError) {
          console.error('Error inserting user:', insertError);
          return;
        }

      } else {
        console.log('User already exists, no need to insert.');
      }

      // Set user state and store in localStorage
      setUser(user);
      localStorage.setItem('authUser', JSON.stringify(user));
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setProfilePicture(null);
      localStorage.removeItem('authUser');
      setIsDropdownOpen(false);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <>
      {/* Header */}
      <header className="bg-[#121212] py-4 px-6 flex items-center justify-between shadow-sm sticky top-0 z-10">
        {/* Left Side: Menu Icon */}
        <div className="flex items-center space-x-4">
          {/* Menu Icon: Visible on Mobile (hidden on large screens) */}
          <button
            onClick={() => setIsSidebarOpen(true)}
            className="text-gray-300 focus:outline-none lg:hidden"
            aria-label="Open sidebar"
          >
            <Menu className="w-6 h-6" />
          </button>
          {/* Golpo Logo: Hidden on Mobile */}
          <Link to="/" className="hidden lg:block text-2xl font-bold text-white">
            Golpo
          </Link>
        </div>

        {/* Search Input */}
        <div className="flex-1 max-w-2xl mx-0 sm:mx-8 flex items-center">
          <div className="relative w-full">
            <input
              type="text"
              placeholder="Search golpos..."
              className="w-full bg-[#1f1f1f] text-white rounded-full py-2 pl-10 pr-4 focus:outline-none focus:ring-2 focus:ring-gray-500"
              value={searchTerm}
              onChange={onSearch}
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          </div>
        </div>

        {/* Right Side: Create Button, Library, and User */}
        <div className="flex items-center space-x-4 mt-4 sm:mt-0">
          {/* Create Button: Hidden on Mobile */}
          <Link to="/create">
            <button className="bg-[#3a86ff] text-white px-4 py-2 rounded-full hover:bg-[#2a75f0] transition-colors shadow-sm hidden sm:block">
              Create
            </button>
          </Link>

          {/* Library Icon: Hidden on Mobile */}
          <Link
            to="/library"
            className={`hidden sm:flex text-gray-300 hover:text-white transition-colors ${
              location.pathname === '/library' ? 'font-semibold text-white' : ''
            }`}
            aria-label="Library"
          >
            <Library className="w-6 h-6" />
          </Link>

          {/* User Dropdown: Hidden on Mobile */}
          {user && user.email ? (
            <div className="relative hidden sm:block" ref={dropdownRef}>
              <button
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="flex items-center justify-center w-8 h-8 rounded-full overflow-hidden focus:outline-none focus:ring-2 focus:ring-gray-500"
                aria-label="User menu"
              >
                {profilePicture ? (
                  <img
                    src={profilePicture}
                    alt="Profile"
                    className="w-full h-full object-cover"
                    onError={() => setProfilePicture(null)}
                  />
                ) : (
                  <div className="w-full h-full bg-gray-600 flex items-center justify-center">
                    <span className="text-white text-sm">
                      {user.displayName ? user.displayName[0].toUpperCase() : '?'}
                    </span>
                  </div>
                )}
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-[#1f1f1f] rounded-md shadow-lg py-1 z-20">
                  <div className="px-4 py-2 text-sm text-gray-300 border-b border-gray-700">
                    {user.displayName || user.email}
                  </div>
                  <button
                    onClick={handleSignOut}
                    className="w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 flex items-center"
                  >
                    <LogOut className="w-4 h-4 mr-2" />
                    Sign out
                  </button>
                </div>
              )}
            </div>
          ) : (
            <button
              onClick={handleSignIn}
              className="hidden sm:flex text-gray-300 hover:text-white transition-colors items-center"
            >
              <User className="w-6 h-6 mr-2" />
              <span>Sign in</span>
            </button>
          )}
        </div>
      </header>

      {/* Sidebar for Mobile */}
      {/* Overlay */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-20 lg:hidden"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}

      {/* Sidebar Panel */}
      <div
        ref={sidebarRef}
        className={`fixed top-0 left-0 h-full w-64 bg-[#121212] shadow-lg transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out z-30 lg:hidden`}
      >
        {/* Close Button */}
        <button
          onClick={() => setIsSidebarOpen(false)}
          className="text-gray-300 hover:text-white focus:outline-none p-4"
          aria-label="Close sidebar"
        >
          <X className="w-6 h-6" />
        </button>

        {/* Sidebar Links */}
        <nav className="flex flex-col px-4 space-y-4">
          {/* Create Link */}
          <Link
            to="/create"
            onClick={() => setIsSidebarOpen(false)}
            className="flex items-center text-gray-300 hover:text-white transition-colors"
          >
            <Library className="w-5 h-5 mr-2" /> {/* Consider replacing with a more appropriate icon */}
            Create
          </Link>

          {/* Library Link */}
          <Link
            to="/library"
            onClick={() => setIsSidebarOpen(false)}
            className={`flex items-center text-gray-300 hover:text-white transition-colors ${
              location.pathname === '/library' ? 'font-semibold text-white' : ''
            }`}
          >
            <Library className="w-5 h-5 mr-2" />
            Library
          </Link>

          {/* Sign In / Sign Out */}
          {user && user.email ? (
            <button
              onClick={() => {
                handleSignOut();
                setIsSidebarOpen(false);
              }}
              className="flex items-center text-gray-300 hover:text-white transition-colors"
            >
              <LogOut className="w-5 h-5 mr-2" />
              Sign out
            </button>
          ) : (
            <button
              onClick={() => {
                handleSignIn();
                setIsSidebarOpen(false);
              }}
              className="flex items-center text-gray-300 hover:text-white transition-colors"
            >
              <User className="w-5 h-5 mr-2" />
              Sign in
            </button>
          )}
        </nav>
      </div>
    </>
  );
};

export default Header;
