// Import the Supabase client library
import { createClient } from '@supabase/supabase-js';

// Supabase credentials
const url = process.env.REACT_APP_SUPABASE_URL;
const key = process.env.REACT_APP_SUPABASE_KEY;

// Create a Supabase client
const supabase = createClient(url, key);

// Export the Supabase client for use in other files
export default supabase;
