import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PodcastProvider } from './PodcastContext';
import Header from './Header';
import Discover from './Discover';
import LibraryScreen from './Library';
import PodcastPlayer from './PodcastPlayer';
import Footer from './Footer';
import PlaylistScreen from './Playlist';
import Create from './Create';
import { AuthProvider } from './AuthContext';
import NewPodcastPopup from './NewPodcastPopup'; // Import the NewPodcastPopup component
import GetMobileApp from './GetMobileApp';

function App() {
  return (
    <AuthProvider>
      <PodcastProvider>
        <Router>
          <div className="min-h-screen bg-gray-900">
            {/* The Popup component */}
            <NewPodcastPopup />
            <GetMobileApp /> 
            {/* Define Routes for Different Screens */}
            <Routes>
              <Route path="/" element={<Discover />} />
              <Route path="/library" element={<LibraryScreen />} />
              <Route path="/podcast" element={<PodcastPlayer />} />
              <Route path="/playlist" element={<PlaylistScreen />} />
              <Route path="/create" element={<Create />} />
            </Routes>

            {/* Global Footer Component */}
            <Footer />
          </div>
        </Router>
      </PodcastProvider>
    </AuthProvider>
  );
}

export default App;
