import React from 'react';
import { Play, Pause, FastForward, Rewind } from 'lucide-react';
import { usePodcast } from './PodcastContext'; // Import the Podcast context

const Footer = () => {
  const {
    podcastTitle,
    podcastImage,
    isPlaying,
    progress,
    duration,
    playAudio,
    pauseAudio,
    setAudioPosition,
    rewindAudio,
    fastForwardAudio,
  } = usePodcast(); // Use Podcast context

  // Conditional rendering: Only show footer if there is a podcast image (URL) to display
  if (!podcastImage) return null;

  const handleSeek = (event) => {
    const seekTime = (event.target.value / 100) * duration;
    setAudioPosition(seekTime);
  };

  return (
    <footer className="fixed bottom-0 left-0 right-0 bg-gray-900 p-4 flex flex-col md:flex-row items-center justify-between px-4 md:px-8 z-50">
      <div className="flex items-center mb-4 md:mb-0">
        <img src={podcastImage} alt={podcastTitle} className="w-12 h-12 object-cover rounded-lg mr-4" />
        <div>
          <p className="text-white text-sm font-semibold">{podcastTitle}</p>
          <p className="text-gray-400 text-xs">Golpo</p>
        </div>
      </div>
      <div className="flex items-center space-x-4 mb-4 md:mb-0">
        <button onClick={rewindAudio} className="text-white hover:text-gray-300">
          <Rewind className="w-5 h-5" />
        </button>
        <button onClick={isPlaying ? pauseAudio : playAudio} className="text-white hover:text-gray-300">
          {isPlaying ? <Pause className="w-6 h-6" /> : <Play className="w-6 h-6" />}
        </button>
        <button onClick={fastForwardAudio} className="text-white hover:text-gray-300">
          <FastForward className="w-5 h-5" />
        </button>
      </div>
      <div className="flex items-center text-white space-x-2 w-full md:w-auto">
        <span className="text-sm">{formatTime((progress / 100) * duration)}</span>
        <input
          type="range"
          value={progress}
          onChange={handleSeek}
          className="flex-1 w-full md:w-32 h-1 bg-gray-700 rounded-full appearance-none cursor-pointer"
          style={{
            background: `linear-gradient(to right, #3b82f6 ${progress}%, #374151 ${progress}%)`,
          }}
        />
        <span className="text-sm">{formatTime(duration)}</span>
      </div>
    </footer>
  );
};

const formatTime = (seconds) => {
  if (isNaN(seconds)) return '00:00';
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes < 10 ? '0' : ''}${minutes}:${secs < 10 ? '0' : ''}${secs}`;
};

export default Footer;
