import React, { useContext, useEffect, useState } from 'react';
import { Play, Clock, ChevronDown, ChevronUp, Share2, CheckCircle, Headphones } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from './Header';
import { AuthContext } from './AuthContext';
import { auth } from './firebase';
import { signInAnonymously } from 'firebase/auth';
import supabase from './supabaseClient';
import { motion, AnimatePresence } from 'framer-motion';

const PlaylistScreen = () => {
  const [playlist, setPlaylist] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [durations, setDurations] = useState({});
  const [showToast, setShowToast] = useState(false);
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const playlistId = searchParams.get('ID');

  const incrementPodcastViews = async (podcastId) => {
    try {
      const { data, error } = await supabase.rpc('increment_views', {
        cur_podcast_id: podcastId,
      });
      if (error) console.error('Error incrementing podcast views via RPC:', error);
      else console.log('Views incremented successfully via RPC:', data);
    } catch (error) {
      console.error('Error in incrementPodcastViews function:', error);
    }
  };

  useEffect(() => {
    const anonymousLogin = async () => {
      const storedAuthUser = localStorage.getItem('authUser');
      if (storedAuthUser) {
        setUser(JSON.parse(storedAuthUser));
      } else {
        try {
          const userCredential = await signInAnonymously(auth);
          const authUser = userCredential.user;
          localStorage.setItem('authUser', JSON.stringify(authUser));
          setUser(authUser);
        } catch (error) {
          console.error('Anonymous login failed:', error);
        }
      }
    };

    if (!user) {
      anonymousLogin();
    } else {
      setLoading(false);
    }
  }, [user, setUser]);

  useEffect(() => {
    if (!user || !playlistId) return;

    const fetchPlaylistById = async () => {
      setLoading(true);
      setPlaylist(null);

      try {
        const { data: playlistData, error: playlistError } = await supabase
          .from('podcasts')
          .select('*')
          .eq('podcast_id', playlistId)
          .eq('type', 'playlist-container')
          .single();

        if (playlistError) throw playlistError;

        incrementPodcastViews(playlistData.podcast_id);

        if (playlistData) {
          setPlaylist(playlistData);

          const podcastsInPlaylist = JSON.parse(playlistData.podcasts_in_playlist || '[]');

          const { data: playlistParts, error: playlistPartsError } = await supabase
            .from('podcasts')
            .select('*')
            .in('podcast_id', podcastsInPlaylist)
            .eq('type', 'long-form');

          if (playlistPartsError) throw playlistPartsError;

          const orderedParts = podcastsInPlaylist.map(podcastId =>
            playlistParts.find(part => part.podcast_id === podcastId)
          );

          setPlaylist(prev => ({ ...prev, parts: orderedParts }));
          calculateAndCacheDurations(orderedParts);
        } else {
          console.log('No playlist found with the specified ID or incorrect type.');
        }
      } catch (error) {
        console.error('Error fetching playlist by ID:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlaylistById();
  }, [user, playlistId, location.key]);

  const calculateAndCacheDurations = async (parts) => {
    const durationPromises = parts.map((part) => {
      return new Promise((resolve) => {
        const audio = new Audio(part.url);
        audio.addEventListener('loadedmetadata', () => {
          const duration = formatTime(audio.duration);
          resolve({ id: part.podcast_id, duration });
        });
      });
    });

    Promise.all(durationPromises).then((durationsArray) => {
      const durationsMap = durationsArray.reduce((acc, { id, duration }) => {
        acc[id] = duration;
        return acc;
      }, {});

      setDurations(durationsMap);
    });
  };

  const formatTime = (seconds) => {
    if (isNaN(seconds)) return '00:00';
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes < 10 ? '0' : ''}${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const toggleDescription = (id) => {
    setExpandedDescriptions(prev => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handlePartClick = (partId) => {
    navigate(`/podcast?ID=${partId}`);
  };

  const handlePlaySeries = () => {
    if (playlist?.parts?.length > 0) {
      navigate(`/podcast?ID=${playlist.parts[0].podcast_id}`);
    }
  };

  const sharePlaylist = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
      })
      .catch((error) => console.error('Could not copy playlist link:', error));
  };

  const truncateDescription = (description, limit = 100) => {
    if (!description) return '';
    return description.length > limit ? `${description.slice(0, limit)}...` : description;
  };

  const handleSearch = (e) => {
    navigate('/', { state: { searchTerm: e.target.value } });
  };

  if (loading) {
    return (
      <div className="bg-gradient-to-br from-gray-900 to-black min-h-screen flex items-center justify-center">
        <div className="text-white text-2xl font-semibold">
          <svg className="animate-spin h-8 w-8 mr-3 inline" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
          </svg>
          Loading playlist...
        </div>
      </div>
    );
  }

  if (!playlist && !loading) {
    return (
      <div className="bg-gradient-to-br from-gray-900 to-black min-h-screen flex items-center justify-center">
        <div className="text-white text-2xl font-semibold">Playlist not found</div>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-br from-gray-900 to-black min-h-screen text-gray-100">
      <Header searchTerm="" onSearch={handleSearch} />
      <main className="max-w-6xl mx-auto px-4 sm:px-6 py-12">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col lg:flex-row items-start mb-12"
        >
          <img
            src={playlist.podcast_image || 'https://via.placeholder.com/300'}
            alt={playlist.title}
            className="w-full lg:w-96 h-96 object-cover rounded-2xl mr-8 shadow-2xl mb-6 lg:mb-0"
          />
          <div className="flex-1">
            <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4 leading-tight tracking-tight text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">
              {playlist.title}
            </h1>
            <p className="text-gray-300 mb-6 text-lg leading-relaxed">
              {showFullDescription ? playlist.description : truncateDescription(playlist.description, 200)}
              <button
                onClick={() => setShowFullDescription(!showFullDescription)}
                className="text-blue-400 hover:text-blue-300 text-sm ml-2 font-medium transition-colors duration-200"
              >
                {showFullDescription ? 'Show Less' : 'Show More'}
              </button>
            </p>
            <div className="flex flex-wrap items-center space-x-4">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handlePlaySeries}
                className="bg-gradient-to-r from-blue-500 to-purple-600 text-white px-8 py-3 rounded-full font-semibold hover:from-blue-600 hover:to-purple-700 transition-all duration-300 flex items-center shadow-lg mb-4 sm:mb-0"
              >
                <Play className="w-5 h-5 mr-2" />
                Play Series
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={sharePlaylist}
                className="bg-gray-700 text-white px-6 py-3 rounded-full font-semibold hover:bg-gray-600 transition-all duration-300 flex items-center shadow-lg"
              >
                <Share2 className="w-5 h-5 mr-2" />
                Share
              </motion.button>
            </div>
          </div>
        </motion.div>

        <div className="space-y-6">
          {playlist.parts && playlist.parts.map((part, index) => (
            <motion.div
              key={part.podcast_id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              className="bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-2xl p-6 hover:bg-opacity-70 transition-all duration-300 cursor-pointer shadow-lg"
              onClick={() => handlePartClick(part.podcast_id)}
            >
              <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-4">
                <h3 className="text-xl sm:text-2xl font-semibold text-blue-300 mb-2 sm:mb-0">{part.title}</h3>
                <div className="flex items-center text-gray-400">
                  <Clock className="w-5 h-5 mr-2" />
                  <span className="font-medium">{durations[part.podcast_id] || 'Loading...'}</span>
                </div>
              </div>
              <p className="text-gray-300 text-base mb-4">
                {expandedDescriptions[part.podcast_id]
                  ? part.description || 'No description available.'
                  : truncateDescription(part.description, 150)}
              </p>
              <div className="flex flex-col sm:flex-row sm:items-center justify-between">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDescription(part.podcast_id);
                  }}
                  className="text-blue-400 text-sm font-medium flex items-center hover:text-blue-300 transition-colors duration-200 mb-2 sm:mb-0"
                >
                  {expandedDescriptions[part.podcast_id] ? (
                    <>
                      Show Less <ChevronUp className="w-4 h-4 ml-1" />
                    </>
                  ) : (
                    <>
                      Show More <ChevronDown className="w-4 h-4 ml-1" />
                    </>
                  )}
                </button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-blue-500 text-white px-6 py-2 rounded-full font-semibold hover:bg-blue-600 transition-all duration-300 flex items-center shadow-md"
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePartClick(part.podcast_id);
                  }}
                >
                  <Headphones className="w-5 h-5 mr-2" />
                  Listen
                </motion.button>
              </div>
            </motion.div>
          ))}
        </div>
      </main>

      <AnimatePresence>
  {showToast && (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.3 }}
      className="fixed bottom-16 left-1/2 bg-green-500 text-white px-6 py-3 rounded-full shadow-lg flex items-center" 
    >
      <CheckCircle className="w-5 h-5 mr-2" />
      Playlist link copied to clipboard!
    </motion.div>
  )}
</AnimatePresence>

    </div>
  );
};

export default PlaylistScreen;