import React, { createContext, useContext, useRef, useState, useEffect } from 'react';

const PodcastContext = createContext();

export const usePodcast = () => useContext(PodcastContext);

export const PodcastProvider = ({ children }) => {
  const audioRef = useRef(null);
  const [audioSrc, setAudioSrc] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [podcastTitle, setPodcastTitle] = useState('');
  const [podcastImage, setPodcastImage] = useState('');

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  useEffect(() => {
    if (audioRef.current) {
      // Pause any currently playing audio
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reset current time
      setIsPlaying(false);
      setProgress(0);
      setDuration(0);
      setPlaybackRate(1);
      audioRef.current.playbackRate = 1;

      // If a new audio source is set, load it and try to play
      if (audioSrc) {
        audioRef.current.load();
        audioRef.current.oncanplaythrough = () => {
          audioRef.current.play().then(() => {
            setIsPlaying(true);
          }).catch(error => {
            console.error('Auto-play failed:', error);
            setIsPlaying(false); // Ensure UI state matches actual state
          });
        };
      }
    }
  }, [audioSrc]);

  // Synchronize play/pause button with actual audio state
  useEffect(() => {
    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    if (audioRef.current) {
      audioRef.current.addEventListener('play', handlePlay);
      audioRef.current.addEventListener('pause', handlePause);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('play', handlePlay);
        audioRef.current.removeEventListener('pause', handlePause);
      }
    };
  }, [audioRef]);

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.error('Play failed:', error);
      });
    }
  };

  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  const setAudioPosition = (position) => {
    if (audioRef.current) {
      audioRef.current.currentTime = position;
      setProgress((position / audioRef.current.duration) * 100);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setProgress((audioRef.current.currentTime / audioRef.current.duration) * 100);
      setDuration(audioRef.current.duration);
    }
  };

  const changePlaybackSpeed = (speed) => {
    setPlaybackRate(speed);
  };

  const rewindAudio = () => {
    if (audioRef.current) {
      setAudioPosition(audioRef.current.currentTime - 15);
    }
  };

  const fastForwardAudio = () => {
    if (audioRef.current) {
      setAudioPosition(audioRef.current.currentTime + 15);
    }
  };

  return (
    <PodcastContext.Provider
      value={{
        audioRef,
        audioSrc,
        setAudioSrc,
        isPlaying,
        playAudio,
        pauseAudio,
        progress,
        setAudioPosition,
        handleTimeUpdate,
        duration,
        playbackRate,
        changePlaybackSpeed,
        rewindAudio,
        fastForwardAudio,
        podcastTitle,
        setPodcastTitle,
        podcastImage,
        setPodcastImage,
      }}
    >
      {children}
      <audio
        ref={audioRef}
        src={audioSrc}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={() => {
          setDuration(audioRef.current.duration);
          setProgress(0);
        }}
        className="hidden"
      />
    </PodcastContext.Provider>
  );
};
