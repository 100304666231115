import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import supabase from './supabaseClient';
import { X, AlertTriangle, ChevronDown, ChevronUp, Headphones } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const CustomAlert = ({ title, description, onClose }) => (
  <motion.div
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-md relative shadow-lg"
    role="alert"
  >
    <button
      className="absolute top-2 right-2 text-red-700 hover:text-red-900 transition-colors duration-200"
      onClick={onClose}
      aria-label="Close alert"
    >
      <X size={20} />
    </button>
    <div className="flex items-center space-x-2">
      <AlertTriangle className="h-5 w-5" />
      <p className="font-bold">{title}</p>
    </div>
    <p className="mt-2">{description}</p>
  </motion.div>
);

const NewPodcastPopup = () => {
  const { user } = useContext(AuthContext);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState(null);
  const [podcastId, setPodcastId] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [showFullDescription, setShowFullDescription] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.email) return;

    const fetchData = async () => {
      const { data, error } = await supabase
        .from('users')
        .select('new, podcast_ids')
        .eq('id', user.email)
        .single();

      if (error) {
        console.error('Error fetching new value:', error);
        return;
      }

      const podcastIds = JSON.parse(data.podcast_ids);
      handleNewValue(data.new, podcastIds[podcastIds.length - 1]);

      const channel = supabase
        .channel('users_changes')
        .on(
          'postgres_changes',
          { event: 'UPDATE', schema: 'public', table: 'users', filter: `id=eq.${user.email}` },
          (payload) => {
            const updatedNewValue = payload.new.new;
            const new_podcast_ids = JSON.parse(payload.new.podcast_ids);
            const updatedPodcastId = new_podcast_ids[new_podcast_ids.length - 1];
            handleNewValue(updatedNewValue, updatedPodcastId);
          }
        )
        .subscribe();

      return () => {
        supabase.removeChannel(channel);
      };
    };

    fetchData();
  }, [user]);

  const handleNewValue = async (newValue, podcastIdValue) => {
    if (newValue === true) {
      const { data: podcastData, error: podcastError } = await supabase
        .from('podcasts')
        .select('*')
        .eq('podcast_id', podcastIdValue)
        .single();

      if (podcastError) {
        console.error('Error fetching latest podcast:', podcastError);
        return;
      }

      if (podcastData) {
        setPodcastId(podcastData.podcast_id);
        setTitle(podcastData.title);
        setDescription(podcastData.description);
        setImageUrl(podcastData.podcast_image);
      }

      setPopupContent('new_podcast');
      setShowPopup(true);
    } else if (newValue === null) {
      setPopupContent('error');
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  };

  const handleClosePopup = async () => {
    setShowPopup(false);
    await updateNewStatus();
  };

  const handleListenClick = async () => {
    await updateNewStatus();
    navigate(`/playlist?ID=${podcastId}`);
  };

  const updateNewStatus = async () => {
    const { error } = await supabase
      .from('users')
      .update({ new: false })
      .eq('id', user.email);

    if (error) {
      console.error('Error updating new to false:', error);
    }
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  if (!user?.email) return null;

  return (
    <AnimatePresence>
      {showPopup && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-70"
        >
          {popupContent === 'new_podcast' ? (
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-gradient-to-br from-indigo-900 to-purple-900 rounded-2xl p-8 max-w-md w-full mx-4 shadow-2xl relative overflow-hidden"
            >
              <button
                className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors duration-200"
                onClick={handleClosePopup}
                aria-label="Close popup"
              >
                <X size={24} />
              </button>

              <div className="space-y-6">
                <motion.div
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  className="space-y-2"
                >
                  <h2 className="text-3xl font-bold text-white">New Golpo Available!</h2>
                  <div className="h-1 w-20 bg-gradient-to-r from-blue-500 to-purple-500"></div>
                </motion.div>
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.4 }}
                  className="flex items-center space-x-4"
                >
                  <img 
                    src={imageUrl} 
                    alt={title} 
                    className="w-24 h-24 rounded-lg object-cover shadow-lg transform hover:scale-105 transition-transform duration-200"
                  />
                  <h3 className="text-xl font-semibold text-gray-100 flex-1">{title}</h3>
                </motion.div>
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.6 }}
                  className="relative"
                >
                  <p className={`text-gray-300 ${showFullDescription ? '' : 'line-clamp-2'}`}>
                    {description}
                  </p>
                  {description.length > 200 && (
                    <button 
                      onClick={toggleDescription}
                      className="mt-2 text-blue-400 hover:text-blue-300 transition-colors duration-200 flex items-center"
                      aria-label={showFullDescription ? 'Show less' : 'Show more'}
                    >
                      {showFullDescription ? (
                        <>
                          Show Less <ChevronUp size={16} className="ml-1" />
                        </>
                      ) : (
                        <>
                          Show More <ChevronDown size={16} className="ml-1" />
                        </>
                      )}
                    </button>
                  )}
                </motion.div>
                <motion.button
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.8 }}
                  onClick={handleListenClick}
                  className="w-full bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white py-3 px-4 rounded-md transition-all duration-200 flex items-center justify-center space-x-2 transform hover:scale-105"
                >
                  <Headphones size={20} />
                  <span>Listen Now</span>
                </motion.button>
              </div>
            </motion.div>
          ) : (
            <CustomAlert
              title="We apologize"
              description="Due to high demand, we couldn't create your podcast at this time. Please try again later. This won't affect your daily limit."
              onClose={handleClosePopup}
            />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default NewPodcastPopup;